import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { ContentEmbedInterface } from "@ihr-radioedit/inferno-core";
import { placeholderImage } from "@ihr-radioedit/inferno-core";
import { MagicLink } from "../ui";
import "./ContentEmbed.style.scss";

export const ContentEmbedLegacy = (props: ContentEmbedInterface) => {
  const { t } = useTranslation();
  const embedClass = classnames("component-content-embed", {
    "with-author": props.author,
  });
  return (
    <figure className={embedClass}>
      <div className="thumb-container">
        <MagicLink to={props.url} target="_blank">
          <img
            src={placeholderImage}
            data-src={props.image}
            alt={`${props.title} - Thumbnail Image`}
            className="lazyload"
          />
        </MagicLink>
      </div>
      <figcaption>
        <p className="content-title">
          <MagicLink to={props.url} target="_blank">
            {props.title}
          </MagicLink>
        </p>
        {props.author ? <p className="content-author">{t("posted", { author: `${props.author}` })}</p> : null}
      </figcaption>
    </figure>
  );
};

export default ContentEmbedLegacy;
