import { GalleryLegacyInterface } from "@ihr-radioedit/inferno-core";
import { galleryToAssetLegacy } from "../lib/gallery-helpers";
import { AssetLegacy } from "./AssetLegacy.component";
import "./Gallery.style.scss";

export const GalleryLegacy = (props: GalleryLegacyInterface) => {
  return (
    <ul className="component-gallery" data-total={props.assets.length}>
      {props.assets.map((item, index) => (
        <li key={index}>
          <AssetLegacy {...galleryToAssetLegacy(item, index + 1, props.assets.length)} />
        </li>
      ))}
    </ul>
  );
};

export default GalleryLegacy;
