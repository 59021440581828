import { AssetBlockInterface, AssetInterface, GalleryInterface, GalleryItem } from "@ihr-radioedit/inferno-core";

export function galleryToAssetLegacy(item: GalleryItem, index = 1, total = 1): AssetInterface {
  return {
    attributes: {
      mimetype: "image/jpeg",
      type: "image",
    },
    bucket: item.bucket,
    id: item.id,
    new_tab: item.new_tab,
    schedule: {},
    title: item.title,
    type: "asset",
    url: item.url,
    caption: item.caption,
    alt_text: item.alt_text,
    counter: `${index} of ${total}`,
    source_credit: item.source_credit,
    use_caption: item.use_caption,
  };
}

export function galleryToAsset(block: GalleryInterface, item: GalleryItem, index = 1, total = 1): AssetBlockInterface {
  return {
    type: "asset",
    schedule: block.schedule,
    value: {
      attributes: {
        mimetype: "image/jpeg",
        type: "image",
      },
      bucket: item.bucket,
      id: item.id,
      new_tab: item.new_tab,
      schedule: {},
      title: item.title,
      type: "asset",
      url: item.url,
      caption: item.caption,
      alt_text: item.alt_text,
      counter: `${index} of ${total}`,
      source_credit: item.source_credit,
      use_caption: item.use_caption,
    },
  };
}
