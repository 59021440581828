import { isLeft } from "fp-ts/lib/Either";
import {
  ContentEmbedInterface,
  IHeartContentLegacyInterface,
  IHeartContentLegacyRequired,
  getPaths,
} from "@ihr-radioedit/inferno-core";
import { ILog } from "@ihr-radioedit/inferno-core";
import { ContentEmbedLegacy } from "./ContentEmbedLegacy.component";

const log = ILog.logger("IHeartContentLegacy.component");

export function IHeartContentLegacy(props: IHeartContentLegacyInterface) {
  const decodedProps = IHeartContentLegacyRequired.decode(props);
  if (isLeft(decodedProps)) {
    log.debug("Invalid Props Provided: ", getPaths(decodedProps));
    return null;
  }
  const contentProps: ContentEmbedInterface = {
    url: props.canonical_url,
    image: props.summary.image,
    title: props.summary.title,
    summary: props.summary.description,
    author: props.summary.author,
  };
  return <ContentEmbedLegacy {...contentProps} />;
}

export default IHeartContentLegacy;
